var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "800px" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-tabs",
            { attrs: { centered: "" } },
            [
              _c("v-tab", [_vm._v("Rubrica")]),
              _c("v-tab-item", { attrs: { align: "center" } }, [
                _c(
                  "div",
                  { staticClass: "pa-5" },
                  [
                    _c("v-text-field", {
                      attrs: {
                        label: "Descrição",
                        dense: "",
                        outlined: "",
                        "persistent-placeholder": "",
                      },
                      model: {
                        value: _vm.rubric.rubricaHistorico.dscRubrDadosrubrica,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.rubric.rubricaHistorico,
                            "dscRubrDadosrubrica",
                            $$v
                          )
                        },
                        expression:
                          "rubric.rubricaHistorico.dscRubrDadosrubrica",
                      },
                    }),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { sm: "6" } },
                          [
                            _c("v-autocomplete", {
                              attrs: {
                                label: "Tipo",
                                "append-icon": "mdi-chevron-down",
                                items: _vm.listaTpRubr,
                                "item-text": "key",
                                multiple: false,
                                dense: "",
                                "hide-details": "",
                                outlined: "",
                                "persistent-placeholder": "",
                              },
                              model: {
                                value:
                                  _vm.rubric.rubricaHistorico
                                    .tpRubrDadosrubrica,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.rubric.rubricaHistorico,
                                    "tpRubrDadosrubrica",
                                    $$v
                                  )
                                },
                                expression:
                                  "rubric.rubricaHistorico.tpRubrDadosrubrica",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { sm: "6" } },
                          [
                            _c("v-autocomplete", {
                              attrs: {
                                label: "Natureza",
                                items: _vm.listaNaturezas,
                                "item-text": "key",
                                multiple: false,
                                dense: "",
                                "append-icon": "mdi-chevron-down",
                                "hide-details": "",
                                outlined: "",
                                "persistent-placeholder": "",
                              },
                              model: {
                                value:
                                  _vm.rubric.rubricaHistorico
                                    .natRubrDadosrubrica,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.rubric.rubricaHistorico,
                                    "natRubrDadosrubrica",
                                    $$v
                                  )
                                },
                                expression:
                                  "rubric.rubricaHistorico.natRubrDadosrubrica",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { sm: "3" } },
                          [
                            _c("v-text-field", {
                              directives: [
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: "##/####",
                                  expression: "'##/####'",
                                },
                              ],
                              attrs: {
                                label: "Início da validade",
                                dense: "",
                                "hide-details": "",
                                outlined: "",
                                "persistent-placeholder": "",
                              },
                              model: {
                                value:
                                  _vm.rubric.rubricaHistorico
                                    .iniValidIderubrica,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.rubric.rubricaHistorico,
                                    "iniValidIderubrica",
                                    $$v
                                  )
                                },
                                expression:
                                  "rubric.rubricaHistorico.iniValidIderubrica",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { sm: "3" } },
                          [
                            _c("v-text-field", {
                              directives: [
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: "##/####",
                                  expression: "'##/####'",
                                },
                              ],
                              attrs: {
                                label: "Fim da validade",
                                dense: "",
                                "hide-details": "",
                                outlined: "",
                                "persistent-placeholder": "",
                              },
                              model: {
                                value:
                                  _vm.rubric.rubricaHistorico
                                    .fimValidIderubrica,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.rubric.rubricaHistorico,
                                    "fimValidIderubrica",
                                    $$v
                                  )
                                },
                                expression:
                                  "rubric.rubricaHistorico.fimValidIderubrica",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { sm: "6" } },
                          [
                            _c("v-autocomplete", {
                              attrs: {
                                label: "cod. Inc. CP",
                                items: _vm.listaCodIncCP,
                                "item-text": "key",
                                multiple: false,
                                dense: "",
                                "append-icon": "mdi-chevron-down",
                                "hide-details": "",
                                outlined: "",
                                "persistent-placeholder": "",
                              },
                              model: {
                                value:
                                  _vm.rubric.rubricaHistorico
                                    .codIncCPDadosrubrica,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.rubric.rubricaHistorico,
                                    "codIncCPDadosrubrica",
                                    $$v
                                  )
                                },
                                expression:
                                  "rubric.rubricaHistorico.codIncCPDadosrubrica",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { sm: "6" } },
                          [
                            _c("v-autocomplete", {
                              attrs: {
                                label: "cod. Inc. IRRF",
                                items: _vm.listaCodIncIR,
                                "item-text": "key",
                                multiple: false,
                                name: "off",
                                dense: "",
                                "append-icon": "mdi-chevron-down",
                                "hide-details": "",
                                outlined: "",
                                "persistent-placeholder": "",
                              },
                              model: {
                                value:
                                  _vm.rubric.rubricaHistorico
                                    .codIncIRRFDadosrubrica,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.rubric.rubricaHistorico,
                                    "codIncIRRFDadosrubrica",
                                    $$v
                                  )
                                },
                                expression:
                                  "rubric.rubricaHistorico.codIncIRRFDadosrubrica",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { sm: "6" } },
                          [
                            _c("v-autocomplete", {
                              attrs: {
                                label: "cod. Inc. FGTS",
                                items: _vm.listaCodIncFgts,
                                "item-text": "key",
                                multiple: false,
                                dense: "",
                                "hide-details": "",
                                "append-icon": "mdi-chevron-down",
                                outlined: "",
                                "persistent-placeholder": "",
                              },
                              model: {
                                value:
                                  _vm.rubric.rubricaHistorico
                                    .codIncFGTSDadosrubrica,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.rubric.rubricaHistorico,
                                    "codIncFGTSDadosrubrica",
                                    $$v
                                  )
                                },
                                expression:
                                  "rubric.rubricaHistorico.codIncFGTSDadosrubrica",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { sm: "12" } },
                          [
                            _c("v-textarea", {
                              attrs: {
                                label: "Observação",
                                counter: 255,
                                dense: "",
                                outlined: "",
                                "persistent-placeholder": "",
                              },
                              model: {
                                value:
                                  _vm.rubric.rubricaHistorico
                                    .observacaoDadosrubrica,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.rubric.rubricaHistorico,
                                    "observacaoDadosrubrica",
                                    $$v
                                  )
                                },
                                expression:
                                  "rubric.rubricaHistorico.observacaoDadosrubrica",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("v-tab", [_vm._v("Processos")]),
              _c("v-tab-item", [
                _c(
                  "div",
                  { staticClass: "pa-5" },
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c("v-data-table", {
                              staticClass: "tableProcessoCp",
                              attrs: {
                                headers: _vm.listaProcessoCpHeader,
                                items: _vm.listaProcessoCp,
                                "items-per-page": 5,
                                dense: "",
                                "hide-default-footer": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "item.codSuspIdeProcessoCP",
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-edit-dialog",
                                        {
                                          attrs: {
                                            "return-value":
                                              item.codSuspIdeProcessoCP,
                                          },
                                          on: {
                                            "update:returnValue": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "codSuspIdeProcessoCP",
                                                $event
                                              )
                                            },
                                            "update:return-value": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "codSuspIdeProcessoCP",
                                                $event
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.codSuspIdeProcessoCP
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "item.extDecisaoIdeProcessoCP",
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-edit-dialog",
                                        {
                                          attrs: {
                                            "return-value":
                                              item.extDecisaoIdeProcessoCP,
                                          },
                                          on: {
                                            "update:returnValue": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "extDecisaoIdeProcessoCP",
                                                $event
                                              )
                                            },
                                            "update:return-value": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "extDecisaoIdeProcessoCP",
                                                $event
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.extDecisaoIdeProcessoCP
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "item.nrProcIdeProcessoCP",
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-edit-dialog",
                                        {
                                          attrs: {
                                            "return-value":
                                              item.nrProcIdeProcessoCP,
                                          },
                                          on: {
                                            "update:returnValue": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "nrProcIdeProcessoCP",
                                                $event
                                              )
                                            },
                                            "update:return-value": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "nrProcIdeProcessoCP",
                                                $event
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.nrProcIdeProcessoCP) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "item.tpProcIdeProcessoCP",
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-edit-dialog",
                                        {
                                          attrs: {
                                            "return-value":
                                              item.tpProcIdeProcessoCP,
                                          },
                                          on: {
                                            "update:returnValue": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "tpProcIdeProcessoCP",
                                                $event
                                              )
                                            },
                                            "update:return-value": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "tpProcIdeProcessoCP",
                                                $event
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.tpProcIdeProcessoCP) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _c("br"),
                        _c("br"),
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c("v-data-table", {
                              staticClass: "tableProcessoFgts",
                              attrs: {
                                headers: _vm.listaProcessoFgtsHeader,
                                items: _vm.listaProcessoFgts,
                                "items-per-page": 5,
                                dense: "",
                                "hide-default-footer": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "item.nrProcIdeProcessoFGTS",
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-edit-dialog",
                                        {
                                          attrs: {
                                            "return-value":
                                              item.nrProcIdeProcessoFGTS,
                                          },
                                          on: {
                                            "update:returnValue": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "nrProcIdeProcessoFGTS",
                                                $event
                                              )
                                            },
                                            "update:return-value": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "nrProcIdeProcessoFGTS",
                                                $event
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.nrProcIdeProcessoFGTS
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _c("br"),
                        _c("br"),
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c("v-data-table", {
                              staticClass: "tableProcessoIrff",
                              attrs: {
                                headers: _vm.listaProcessoIrffHeader,
                                items: _vm.listaProcessoIrrf,
                                "items-per-page": 5,
                                dense: "",
                                "hide-default-footer": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "item.nrProcIdeProcessoIRRF",
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-edit-dialog",
                                        {
                                          attrs: {
                                            "return-value":
                                              item.nrProcIdeProcessoIRRF,
                                          },
                                          on: {
                                            "update:returnValue": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "nrProcIdeProcessoIRRF",
                                                $event
                                              )
                                            },
                                            "update:return-value": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "nrProcIdeProcessoIRRF",
                                                $event
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.nrProcIdeProcessoIRRF
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "item.codSuspIdeProcessoIRRF",
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-edit-dialog",
                                        {
                                          attrs: {
                                            "return-value":
                                              item.codSuspIdeProcessoIRRF,
                                          },
                                          on: {
                                            "update:returnValue": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "codSuspIdeProcessoIRRF",
                                                $event
                                              )
                                            },
                                            "update:return-value": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "codSuspIdeProcessoIRRF",
                                                $event
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.codSuspIdeProcessoIRRF
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _c("br"),
                        _c("br"),
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c("v-data-table", {
                              staticClass: "tableProcessoSind",
                              attrs: {
                                headers: _vm.listaProcessoSindHeader,
                                items: _vm.listaProcessoSind,
                                "items-per-page": 5,
                                dense: "",
                                "hide-default-footer": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "item.nrProcIdeProcessoSIND",
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-edit-dialog",
                                        {
                                          attrs: {
                                            "return-value":
                                              item.nrProcIdeProcessoSIND,
                                          },
                                          on: {
                                            "update:returnValue": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "nrProcIdeProcessoSIND",
                                                $event
                                              )
                                            },
                                            "update:return-value": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "nrProcIdeProcessoSIND",
                                                $event
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.nrProcIdeProcessoSIND
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "item.codSuspIdeProcessoSIND",
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-edit-dialog",
                                        {
                                          attrs: {
                                            "return-value":
                                              item.codSuspIdeProcessoSIND,
                                          },
                                          on: {
                                            "update:returnValue": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "codSuspIdeProcessoSIND",
                                                $event
                                              )
                                            },
                                            "update:return-value": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                item,
                                                "codSuspIdeProcessoSIND",
                                                $event
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.codSuspIdeProcessoSIND
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("v-tab", [_vm._v("Aplicabilidade")]),
              _c("v-tab-item", [
                _c(
                  "div",
                  { staticClass: "pa-5" },
                  [
                    _c("v-textarea", {
                      attrs: {
                        label: "Aplicabilidade",
                        dense: "",
                        outlined: "",
                        "persistent-placeholder": "",
                      },
                      model: {
                        value: _vm.rubric.rubricaHistorico.aplicabilidade,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.rubric.rubricaHistorico,
                            "aplicabilidade",
                            $$v
                          )
                        },
                        expression: "rubric.rubricaHistorico.aplicabilidade",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c("v-btn", { attrs: { text: "" }, on: { click: _vm.close } }, [
                _vm._v(" Cancelar"),
              ]),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "success",
                    loading: _vm.loadingSave,
                    disabled: _vm.loadingSave,
                  },
                  on: { click: _vm.salvar },
                },
                [_vm._v(" Salvar ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }