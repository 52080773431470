<template>
  <v-dialog v-model="show" max-width="800px">
    <v-card>
      <v-tabs centered>
        <v-tab>Rubrica</v-tab>
        <v-tab-item align="center">
          <div class="pa-5">
            <v-text-field
              v-model="rubric.rubricaHistorico.dscRubrDadosrubrica"
              label="Descrição"
              dense
              outlined
              persistent-placeholder
            />

            <v-row>
              <v-col sm="6">
                <v-autocomplete
                  v-model="rubric.rubricaHistorico.tpRubrDadosrubrica"
                  label="Tipo"
                  append-icon="mdi-chevron-down"
                  :items="listaTpRubr"
                  item-text="key"
                  :multiple="false"
                  dense
                  hide-details
                  outlined
                  persistent-placeholder
                />
              </v-col>
              <v-col sm="6">
                <v-autocomplete
                  v-model="rubric.rubricaHistorico.natRubrDadosrubrica"
                  label="Natureza"
                  :items="listaNaturezas"
                  item-text="key"
                  :multiple="false"
                  dense
                  append-icon="mdi-chevron-down"
                  hide-details
                  outlined
                  persistent-placeholder
                />
              </v-col>

              <v-col sm="3">
                <v-text-field
                  v-model="rubric.rubricaHistorico.iniValidIderubrica"
                  v-mask="'##/####'"
                  label="Início da validade"
                  dense
                  hide-details
                  outlined
                  persistent-placeholder
                />
              </v-col>
              <v-col sm="3">
                <v-text-field
                  v-model="rubric.rubricaHistorico.fimValidIderubrica"
                  v-mask="'##/####'"
                  label="Fim da validade"
                  dense
                  hide-details
                  outlined
                  persistent-placeholder
                />
              </v-col>

              <v-col sm="6">
                <v-autocomplete
                  v-model="rubric.rubricaHistorico.codIncCPDadosrubrica"
                  label="cod. Inc. CP"
                  :items="listaCodIncCP"
                  item-text="key"
                  :multiple="false"
                  dense
                  append-icon="mdi-chevron-down"
                  hide-details
                  outlined
                  persistent-placeholder
                />
              </v-col>
              <v-col sm="6">
                <v-autocomplete
                  v-model="rubric.rubricaHistorico.codIncIRRFDadosrubrica"
                  label="cod. Inc. IRRF"
                  :items="listaCodIncIR"
                  item-text="key"
                  :multiple="false"
                  name="off"
                  dense
                  append-icon="mdi-chevron-down"
                  hide-details
                  outlined
                  persistent-placeholder
                />
              </v-col>
              <v-col sm="6">
                <v-autocomplete
                  v-model="rubric.rubricaHistorico.codIncFGTSDadosrubrica"
                  label="cod. Inc. FGTS"
                  :items="listaCodIncFgts"
                  item-text="key"
                  :multiple="false"
                  dense
                  hide-details
                  append-icon="mdi-chevron-down"
                  outlined
                  persistent-placeholder
                />
              </v-col>

              <v-col sm="12">
                <v-textarea
                  v-model="rubric.rubricaHistorico.observacaoDadosrubrica"
                  label="Observação"
                  :counter="255"
                  dense
                  outlined
                  persistent-placeholder
                />
              </v-col>
            </v-row>
          </div>
        </v-tab-item>
        <v-tab>Processos</v-tab>
        <v-tab-item>
          <div class="pa-5">
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="listaProcessoCpHeader"
                  :items="listaProcessoCp"
                  :items-per-page="5"
                  dense
                  hide-default-footer
                  class="tableProcessoCp"
                >
                  <template v-slot:item.codSuspIdeProcessoCP="{ item }">
                    <v-edit-dialog :return-value.sync="item.codSuspIdeProcessoCP">
                      {{ item.codSuspIdeProcessoCP }}
                    </v-edit-dialog>
                  </template>
                  <template v-slot:item.extDecisaoIdeProcessoCP="{ item }">
                    <v-edit-dialog :return-value.sync="item.extDecisaoIdeProcessoCP">
                      {{ item.extDecisaoIdeProcessoCP }}
                    </v-edit-dialog>
                  </template>
                  <template v-slot:item.nrProcIdeProcessoCP="{ item }">
                    <v-edit-dialog :return-value.sync="item.nrProcIdeProcessoCP">
                      {{ item.nrProcIdeProcessoCP }}
                    </v-edit-dialog>
                  </template>
                  <template v-slot:item.tpProcIdeProcessoCP="{ item }">
                    <v-edit-dialog :return-value.sync="item.tpProcIdeProcessoCP">
                      {{ item.tpProcIdeProcessoCP }}
                    </v-edit-dialog>
                  </template>
                </v-data-table>
              </v-col>
              <br />
              <br />
              <v-col cols="12">
                <v-data-table
                  :headers="listaProcessoFgtsHeader"
                  :items="listaProcessoFgts"
                  :items-per-page="5"
                  dense
                  hide-default-footer
                  class="tableProcessoFgts"
                >
                  <template v-slot:item.nrProcIdeProcessoFGTS="{ item }">
                    <v-edit-dialog :return-value.sync="item.nrProcIdeProcessoFGTS">
                      {{ item.nrProcIdeProcessoFGTS }}
                    </v-edit-dialog>
                  </template>
                </v-data-table>
              </v-col>
              <br />
              <br />
              <v-col cols="12">
                <v-data-table
                  :headers="listaProcessoIrffHeader"
                  :items="listaProcessoIrrf"
                  :items-per-page="5"
                  dense
                  hide-default-footer
                  class="tableProcessoIrff"
                >
                  <template v-slot:item.nrProcIdeProcessoIRRF="{ item }">
                    <v-edit-dialog :return-value.sync="item.nrProcIdeProcessoIRRF">
                      {{ item.nrProcIdeProcessoIRRF }}
                    </v-edit-dialog>
                  </template>
                  <template v-slot:item.codSuspIdeProcessoIRRF="{ item }">
                    <v-edit-dialog :return-value.sync="item.codSuspIdeProcessoIRRF">
                      {{ item.codSuspIdeProcessoIRRF }}
                    </v-edit-dialog>
                  </template>
                </v-data-table>
              </v-col>
              <br />
              <br />
              <v-col cols="12">
                <v-data-table
                  :headers="listaProcessoSindHeader"
                  :items="listaProcessoSind"
                  :items-per-page="5"
                  dense
                  hide-default-footer
                  class="tableProcessoSind"
                >
                  <template v-slot:item.nrProcIdeProcessoSIND="{ item }">
                    <v-edit-dialog :return-value.sync="item.nrProcIdeProcessoSIND">
                      {{ item.nrProcIdeProcessoSIND }}
                    </v-edit-dialog>
                  </template>
                  <template v-slot:item.codSuspIdeProcessoSIND="{ item }">
                    <v-edit-dialog :return-value.sync="item.codSuspIdeProcessoSIND">
                      {{ item.codSuspIdeProcessoSIND }}
                    </v-edit-dialog>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </div>
        </v-tab-item>
        <v-tab>Aplicabilidade</v-tab>
        <v-tab-item>
          <div class="pa-5">
            <v-textarea
              v-model="rubric.rubricaHistorico.aplicabilidade"
              label="Aplicabilidade"
              dense
              outlined
              persistent-placeholder
            />
          </div>
        </v-tab-item>
      </v-tabs>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="close"> Cancelar</v-btn>
        <v-btn color="success" :loading="loadingSave" :disabled="loadingSave" @click="salvar"> Salvar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import descCodigosVSelect from '@/assets/json/descCodigosVSelect.json';
import eSocialService from '@/services/eSocialService.js';
import cloneDeep from 'lodash/cloneDeep';
import { eventBus } from '../../../../main.js';

export default {
  name: 'ModalEditRubric',

  props: ['rubricOrigin'],

  data() {
    return {
      show: false,
      loadingSave: false,
      rubric: { idEvtTabRubrica: '', rubricaHistorico: {} },
      listaProcessoCp: [],
      listaProcessoIrrf: [],
      listaProcessoFgts: [],
      listaProcessoSind: [],
      listaNaturezas: descCodigosVSelect.natRubr,
      listaTpRubr: descCodigosVSelect.tpRubr,
      listaCodIncCP: descCodigosVSelect.codIncCP,
      listaCodIncIR: descCodigosVSelect.codIncIR,
      listaCodIncFgts: descCodigosVSelect.codIncFGTS,
      listaProcessoCpHeader: [
        {
          align: 'center',
          text: 'Código do indicativo da suspensão Contribuição Previdenciária',
          value: 'codSuspIdeProcessoCP',
        },
        {
          align: 'center',
          text: 'Extensão da decisão/sentença Contribuição Previdenciária',
          value: 'extDecisaoIdeProcessoCP',
        },
        { align: 'center', text: 'Número do Processo Contribuição Previdenciária', value: 'nrProcIdeProcessoCP' },
        { align: 'center', text: 'Tipo de Processo Contribuição Previdenciária', value: 'tpProcIdeProcessoCP' },
      ],

      listaProcessoIrffHeader: [
        { align: 'center', text: 'Número do Processo IRRF', value: 'nrProcIdeProcessoIRRF' },
        { align: 'center', text: 'Código do indicativo da suspensão IRRF', value: 'codSuspIdeProcessoIRRF' },
      ],

      listaProcessoFgtsHeader: [{ align: 'center', text: 'Número do Processo FGTS', value: 'nrProcIdeProcessoFGTS' }],
      listaProcessoSindHeader: [
        { align: 'center', text: 'Número do Processo Contribuição Sindical', value: 'nrProcIdeProcessoSIND' },
      ],
      toast: {
        message: '',
        type: '',
        show: false,
        topToast: 0,
      },
    };
  },

  created() {
    this.init();
  },

  methods: {
    init() {
      this.$on('open', this.open);
      this.$on('edit', this.edit);
      this.$on('close', this.close);
      this.$on('conciliar', this.conciliar);
    },

    open() {
      this.show = true;
    },

    edit(payload) {
      let itemForReturn = {};
      const rubric = cloneDeep(payload);
      if (rubric.iniValidIderubrica) {
        const iniValidIderubricaSplit = rubric.iniValidIderubrica.split('-');
        rubric.iniValidIderubrica = `${iniValidIderubricaSplit[1]}/${iniValidIderubricaSplit[0]}`;
      }
      if (rubric.fimValidIderubrica) {
        const fimValidIderubricaSplit = rubric.fimValidIderubrica.split('-');
        rubric.fimValidIderubrica = `${fimValidIderubricaSplit[1]}/${fimValidIderubricaSplit[0]}`;
      }
      this.showMsgEsocialUpdated = true;
      if (this.idEsocialUpdated != rubric.id) {
        this.showMsgEsocialUpdated = false;
      }
      let loadProccess = this.rubricOrigin;

      this.loadInfoCP(loadProccess);
      this.loadInfoIRRF(loadProccess);
      this.loadInfoFGTS(loadProccess);
      this.loadInfoSIND(loadProccess);
      if (rubric.evtTabRubrica !== undefined) rubric.dataRegistro = new Date();

      delete rubric.id;
      if (rubric.evtTabRubrica) {
        delete rubric.evtTabRubrica;
      }
      itemForReturn = { idEvtTabRubrica: this.rubricOrigin.id.toString(), rubricaHistorico: rubric };
      this.rubric = itemForReturn;
      this.show = true;
    },

    close() {
      this.show = false;
    },

    conciliar(rubricaHistorico) {
      if (rubricaHistorico) {
        this.edit(rubricaHistorico);
        this.close();
        this.salvar('CONCILIADA');
      }
    },

    async salvar(status) {
      this.loadingSave = true;
      try {
        this.showMsgEsocialUpdated = true;
        if (this.idEsocialUpdated != this.rubric.id) {
          this.showMsgEsocialUpdated = false;
        }
        let itemToSave = cloneDeep(this.rubric);

        if (itemToSave.rubricaHistorico.iniValidIderubrica) {
          const iniValidIderubricaSplit = itemToSave.rubricaHistorico.iniValidIderubrica.split('/');
          itemToSave.rubricaHistorico.iniValidIderubrica = `${iniValidIderubricaSplit[1]}-${iniValidIderubricaSplit[0]}`;
        }
        if (itemToSave.rubricaHistorico.fimValidIderubrica) {
          const fimValidIderubricaSplit = itemToSave.rubricaHistorico.fimValidIderubrica.split('/');
          itemToSave.rubricaHistorico.fimValidIderubrica = `${fimValidIderubricaSplit[1]}-${fimValidIderubricaSplit[0]}`;
        }

        itemToSave.rubricaHistorico.codIncCPDadosrubrica = itemToSave.rubricaHistorico.codIncCPDadosrubrica.substring(
          0,
          2,
        );
        itemToSave.rubricaHistorico.codIncFGTSDadosrubrica =
          itemToSave.rubricaHistorico.codIncFGTSDadosrubrica.substring(0, 2);
        itemToSave.rubricaHistorico.codIncIRRFDadosrubrica =
          itemToSave.rubricaHistorico.codIncIRRFDadosrubrica.substring(0, 2);
        itemToSave.rubricaHistorico.natRubrDadosrubrica = itemToSave.rubricaHistorico.natRubrDadosrubrica.substring(
          0,
          4,
        );
        itemToSave.rubricaHistorico.tpRubrDadosrubrica = itemToSave.rubricaHistorico.tpRubrDadosrubrica.substring(0, 1);

        if (typeof status == 'string') {
          itemToSave.rubricaHistorico.status = status;
        } else {
          itemToSave.rubricaHistorico.status = 'PENDENTE_APROVACAO';
        }
        if (itemToSave.id === undefined) {
          await eSocialService.rubricahistorico.save(itemToSave);
          this.close();
        } else {
          await eSocialService.rubricahistorico.update(itemToSave);
          this.close();
        }
        this.$emit('success');
        this.showToast(`Alterações realizadas com sucesso!`, 'success', 78);
      } catch (e) {

        if (e.response && e.response.data && e.response.data.includes('rubric has suggestions')) {
          this.showToast(`Existem sugestões para essa rubrica! Não é possivel conciliar.`, 'error', 78);
        }
        console.log(e);
      }
      this.loadingSave = false;
    },

    loadInfoCP(item) {
      let listado = item.listEvtTabRubricaIdeProcessoCP;

      this.listaProcessoCp = listado;
    },

    loadInfoFGTS(item) {
      let listado = item.listEvtTabRubricaIdeProcessoFGTS;
      this.listaProcessoFgts = listado;
    },

    loadInfoIRRF(item) {
      let listado = item.listEvtTabRubricaIdeProcessoIRRF;
      this.listaProcessoIrrf = listado;
    },
    loadInfoSIND(item) {
      let listado = item.listEvtTabRubricaIdeProcessoSIND;
      this.listaProcessoSindi = listado;
    },
    showToast(message, type, topToast) {
      this.toast.show = true;
      this.toast.type = type;
      this.toast.message = message;
      this.toast.topToast = topToast;
      eventBus.$emit('openToast', this.toast);
    },
  },
};
</script>
